export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
        radius: { value: 0.0},
        stepSize: { value: 0.001},
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform float stepSize;',
        'uniform float radius;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '   float value = texture2D(tDiffuse, vUV).r;',
        '   float expandedVal = value;',
        '   int steps = int(round(abs(radius)));',

        //read out the texels
        '   for (int i=-steps; i <= steps; ++i) {',
        '      for (int j=-steps; j <= steps; ++j) {',
        // color at pixel in the neighborhood
        '          vec2 coord = vUV + vec2(float(i), float(j))*stepSize;',
        '          float cmpVal = texture2D(tDiffuse, coord).r;',

        // dilate = max, erode = min
        '           if (radius < 0.0) {',
        '               expandedVal = min(expandedVal, cmpVal);',
        '           }',
        '           else {',
        '               expandedVal = max(expandedVal, cmpVal);',
        '           }',
        '      }',
        '   }',
            
        '   gl_FragColor = vec4(vec3(expandedVal), 1.0);',
        '}'
    ].join('\n')
  }