import React, { useRef, useState } from 'react'
import { Canvas, useFrame, extend, useThree } from '@react-three/fiber'
import { TextureBillboard } from "./TextureBillboard"
import { PSBrightnessShader, AdditiveBrightnessShader} from "./Shaders"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { useEffect } from 'react';

extend({ ShaderPass, EffectComposer, RenderPass });

function Effects(props) {
    const {brightnessClass, brightness} = props;
    const { scene, gl, size, camera } = useThree()
    const brightnessPass = useRef();
    const composer = useRef();

    useFrame(() => {
        if (brightnessPass.current) {
            const t = new Date().getTime();
            brightnessPass.current.uniforms.brightness.value = brightness(t);
        }
        composer.current.render();
    }, 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        {true && <shaderPass ref={brightnessPass} attachArray="passes" args={[brightnessClass]} />}
    </effectComposer>
}

export function Scene(props) {
    const [canvasDims, setCanvasDims] = useState({ width: 700, height: 700 });

    const speed = 1/2000;
    const brightnessPS =  (t) => Math.sin(t*speed)*1.3+1.5;
    const brightnessAdd = (t) => Math.sin(t*speed)/3.0-0.0;

    return <div style={{display: 'flex', flexDirection: "row"}}>
    <Canvas style={canvasDims}>
        <React.Suspense fallback={<mesh />}>
            <TextureBillboard
                textureUrl={"none"}
                canvasDims={canvasDims}
                setCanvasDims={setCanvasDims} />
        </React.Suspense>
        <Effects brightnessClass={PSBrightnessShader} brightness={brightnessPS}/>
    </Canvas>
    <Canvas style={canvasDims}>
        <React.Suspense fallback={<mesh />}>
            <TextureBillboard
                textureUrl={"none"}
                canvasDims={canvasDims}
                setCanvasDims={setCanvasDims} />
        </React.Suspense>
        <Effects brightnessClass={AdditiveBrightnessShader} brightness={brightnessAdd}/>
    </Canvas>
    </div>
;
}