import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Typography, Slider, Tooltip} from '@material-ui/core';

export function CustomSlider (props) {
    const { labelText, tooltipTitle } = props;

    return (
        <Tooltip open={!tooltipTitle
        ? false : undefined} title={tooltipTitle}>
            <div style={{width: "100%"}}>
                <Typography id="discrete-slider" gutterBottom>
                    {labelText}
                </Typography> 
                <Slider
                    {...props}
                />
            </div>
        </Tooltip>
        );
}