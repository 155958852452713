import {Matrix4} from 'three'

const identity = new Matrix4();

export const defaultFragCode = [
    '//example implements power-based contrast', 
    '',
    'void main(void) {',
    '    vec3 col = texture2D(tDiffuse, vUV).rgb;',
    '    col = col*2.0 - 1.0;',
    '    vec3 sig = sign(col);',
    '    col = sig*pow(abs(col), vec3(1.0/(pow(0.5+customParam,2.0))));',
    '    col = col*0.5 + 0.5;',
        
    '    gl_FragColor = vec4(col, 1.0);',
    '}'
].join('\n');

export function getCustomShader (fragCode){
    if (!fragCode) {
        fragCode = defaultFragCode;
    }

    const shaderObj =  {

    uniforms: {
        tDiffuse: { type: 't', value: null},
        customParam: {value: 1.0}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform float customParam;',
        'varying vec2 vUV;',
    ].join('\n') + '\n' + fragCode
  }
  return shaderObj;
}