import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'varying vec2 vUV;',
        
        'void main(void) {',
        '    vec4 col = texture2D(tDiffuse, vUV);',
            
        '    gl_FragColor = vec4(col.rgb / col.a, 1.0);',
        '}'
    ].join('\n')
  }