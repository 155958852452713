import {Matrix4} from 'three'

const identity = new Matrix4();

export default {
    uniforms: {
        tDiffuse: { type: 't', value: null}, // alpha
        tOrigImage: { type: 't', value: null},
        hue: {value: 0.0},
        sat: {value: 0.0},
        contrast: {value: 1.0},
        gamma: {value: 1.0}
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = projectionMatrix * viewMatrix * modelMatrix * vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'uniform sampler2D tOrigImage;',
        'uniform float gamma;',
        'uniform float hue;',
        'uniform float sat;',
        'uniform float contrast;',
        'varying vec2 vUV;',

        'vec3 rgb2hsv(vec3 c) {',
        '   vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);',
        '   vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));',
        '   vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));',

        '   float d = q.x - min(q.w, q.y);',
        '   float e = 1.0e-10;',
        '   return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);',
        '}',

        'vec3 hsv2rgb(vec3 c)',
        '{',
        '   vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);',
        '   vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);',
        '   return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);',
        '}',

        'vec3 applyContrast(vec3 c, float contrast)',
        '{',
        '   vec3 outC = c * 2.0 - vec3(1.0);',
        '   vec3 s = sign(outC);',
        '   outC = vec3(1.0) - abs(outC);',
        '   outC = pow(outC, vec3(contrast));',
        '   outC = vec3(1.0) - outC;',
        '   outC = outC * s;',
        '   return clamp(outC * 0.5 + vec3(0.5), 0.0, 1.0);',
        '}',
        
        'void main(void) {',
        '   float alpha = texture2D(tDiffuse, vUV).r;',
        '   vec3 col = texture2D(tOrigImage, vUV).rgb;',

        '   col = rgb2hsv(col);',
        '   col.r += hue;',
        '   col.g *= sat;',
        '   col = hsv2rgb(col);',

        '   col = gamma > 1.0 ? 1.0-pow(1.0-col, vec3(gamma)) : pow(col, vec3(1.0/gamma));',
        '   col = applyContrast(col, contrast);',
            
        '   gl_FragColor = vec4(col*alpha, alpha);',
        '}'
    ].join('\n')
  }