/**
 * based on 'https://threejsfundamentals.org/threejs/resources/threejs/r115/examples/jsm/postprocessing/BloomPass.js';
 */

import {
	AdditiveBlending,
	LinearFilter,
	RGBAFormat,
	ShaderMaterial,
	UniformsUtils,
	Vector2,
	WebGLRenderTarget,
	FloatType
} from "three";
import *  as THREE from 'three'
import { Pass, FullScreenQuad } from "three/examples/jsm/postprocessing/Pass";
import { AddShader, AlphaDivideShader, AlphaShader } from "../Shaders";

function getMaterialFromShader(shaderClass) {
    return new ShaderMaterial({
        uniforms: { ...THREE.UniformsUtils.clone(shaderClass.uniforms) },
        vertexShader: shaderClass.vertexShader,
        fragmentShader: shaderClass.fragmentShader
    });
}

class AddPass extends Pass {
	constructor(size) {
		super();
		this.size = size;

		// render target
	
		const texParams = { minFilter: LinearFilter, magFilter: LinearFilter, type: FloatType, format: RGBAFormat };
		this.renderTargetA = new WebGLRenderTarget( size.width, size.height, texParams );

		this.texturesToAdd = [];
	
		this.alphaMaterial = getMaterialFromShader(AlphaShader);
		this.alphaMaterial.transparent = true;
		this.alphaMaterial.blending = AdditiveBlending;

		this.alphaDivideMaterial = getMaterialFromShader(AlphaDivideShader);
	
		this.fsQuad = new FullScreenQuad( null );
	}

	render(renderer, writeBuffer, /*readBuffer, deltaTime, maskActive*/ ) {
		
		// Render quad with blured scene into texture (convolution pass 1)

		this.fsQuad.material = this.alphaMaterial;
		renderer.autoClear = false;
		renderer.setRenderTarget( this.renderTargetA );
		renderer.clear( );

		this.texturesToAdd.forEach(({texture, weight}) => {
			renderer.setRenderTarget( this.renderTargetA );

			this.alphaMaterial.uniforms[ "tDiffuse" ].value = texture;
			this.alphaMaterial.uniforms[ "alpha" ].value = weight;
			this.fsQuad.render( renderer );
			this.renderToA = !this.renderToA;
		});


		this.alphaDivideMaterial.uniforms[ "tDiffuse" ].value = this.renderTargetA.texture;
		this.fsQuad.material = this.alphaDivideMaterial;

		renderer.setRenderTarget( writeBuffer );
		renderer.clear( );
		this.fsQuad.render( renderer );
	}
}

export { AddPass };