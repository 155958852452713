import {Matrix4} from 'three'


export default {
    uniforms: {
        tDiffuse: { type: 't', value: null},
    },
  
    vertexShader: [
        'varying vec2 vUV;',
        'void main(void) {',
        '    gl_Position = vec4(position, 1.0);',
        '    vUV = uv;',
        '}'
    ].join('\n'),
  
    fragmentShader: [
        'precision highp float;',
        'precision highp sampler2D;',
        'uniform sampler2D tDiffuse;',
        'varying vec2 vUV;',

        'void main(void) {',
        '    vec3 col = texture(tDiffuse, vUV).rgb;',
            
        '    gl_FragColor = vec4(col, 1.0);',
        '}'
    ].join('\n')
  }